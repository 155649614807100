import { Platforms } from 'shared/types/platforms';
import LabelLink from '../LabelLink/LabelLink';
import PlatformIcon, { IconSize } from '../PlatformIcon/PlatformIcon';
import './PlatformValueCombo.scss';

export default function PlatformValueCombo({
  platform,
  value,
  url,
  iconSize = 'large',
}: {
  platform: keyof typeof Platforms;
  value?: string;
  url?: string;
  iconSize?: IconSize;
}) {
  return (
    <div className="combo-container">
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          <PlatformIcon
            platform={platform}
            size={iconSize}
            colorType="blackWhite"
          />
        </a>
      ) : (
        <PlatformIcon
          platform={platform}
          size={iconSize}
          colorType="blackWhite"
        />
      )}

      {url && value ? (
        <LabelLink href={url} label={value} linkType="external" />
      ) : (
        value ?? null
      )}
    </div>
  );
}
