// @ts-nocheck
import React, { forwardRef } from 'react';
import classnames from 'classnames';
import Tippy from '@tippyjs/react';
import './IconButton.scss';

const cbn = 'ui-icon-button';

type UIIconButtonSize = 'medium' | 'mid-small' | 'small' | 'xsmall';

type UIIconButtonWidth =
  | null
  | 'xxx-small'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'mid-small'
  | 'medium'
  | 'mid-large'
  | 'large';

type UIIconButtonProps = Partial<
  WithChildren<{
    active: boolean;
    className: string | undefined;
    disabled: boolean | undefined;
    icon: string | null;
    id: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    size: UIIconButtonSize;
    tooltip: React.ReactElement | string | null;
    width: UIIconButtonWidth;
  }>
>;

const UIIconButton = forwardRef<HTMLButtonElement, UIIconButtonProps>(
  (
    {
      active,
      children,
      className,
      disabled,
      icon,
      id,
      onClick,
      size,
      tooltip,
      width,
    }: UIIconButtonProps,
    ref,
  ) => {
    const render = () => (
      <button
        type="button"
        id={id}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        className={classnames(cbn, {
          [`${cbn}--active`]: active,
          [`${cbn}--${width}`]: width,
          [`${cbn}--size-${size}`]: size,
          [`${cbn}--has-children`]: children,
        })}
      >
        <span
          className={classnames(`${cbn}__icon`, `icon-${icon}`, className)}
        />
        {children && <span className={`${cbn}__text`}>{children}</span>}
      </button>
    );

    if (!tooltip) {
      return render();
    }

    return (
      <Tippy
        content={tooltip}
        animation="shift-toward"
        className={`${cbn}__tooltip`}
        arrow
      >
        {render()}
      </Tippy>
    );
  },
);

UIIconButton.defaultProps = {
  active: false,
  children: null,
  className: null,
  disabled: false,
  id: undefined,
  onClick: undefined,
  size: 'medium',
  tooltip: null,
  width: undefined,
};

export default UIIconButton;
