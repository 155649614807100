export const isAwareness = (campaign) =>
  campaign.subType?.toLowerCase()?.includes('awareness');

export const isPerformance = (campaign) =>
  campaign.subType?.toLowerCase()?.includes('performance');

export function onPostSort(rowNodes) {
  function isRejected(node) {
    return (
      node?.data?.executionStatusName?.includes('Rejected') ??
      node?.data?.executionStatusPhase?.toLowerCase().includes('rejected')
    );
  }

  function move(toIndex, fromIndex) {
    rowNodes.splice(toIndex, 0, rowNodes.splice(fromIndex, 1)[0]);
  }

  for (let i = 0; i < rowNodes.length; i++) {
    if (isRejected(rowNodes[i])) {
      move(rowNodes.length, i);
    }
  }
}

export const approvalValueMap = {
  true: 'Approved',
  false: 'Rejected',
  default: null,
};
