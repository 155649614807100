import { useRef, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import absoluteHeight from './utils/absoluteHeight';

export default function useGridAutoSize(
  { height, heightRatio },
  reportingOutcome,
) {
  const agRef = useRef(null);
  const [agHeight, setAgHeight] = useState(0);

  useEffect(() => {
    if (reportingOutcome) {
      return;
    }
    const handler = debounce(() => {
      if (height !== 'adaptative' || !agRef.current) {
        return;
      }

      const bodyZenMode = Boolean(
        document.querySelector('body').classList.contains('body-zen-mode'),
      );

      const footerHeight = bodyZenMode
        ? 0
        : absoluteHeight(document.querySelector('.footer'));
      const headerHeight = absoluteHeight(
        document.querySelector('.app-header'),
      );

      const othersHeight = Array.from(agRef.current.parentNode.children)
        .filter((node) => node !== agRef.current)
        .reduce((acc, cur) => acc + absoluteHeight(cur), 0);

      const { innerHeight } = window;
      // Ensure the grid is at least half as tall as the screen, on smaller
      // screens the page will be scrollable.
      const newHeight = Math.max(
        innerHeight / heightRatio,
        innerHeight - headerHeight - footerHeight - othersHeight,
      );

      setAgHeight(newHeight);
    }, 300);

    const observer = new MutationObserver((mutations) => {
      if (mutations.some((mutation) => mutation.attributeName === 'class')) {
        handler();
      }
    });

    window.addEventListener('resize', handler);
    window.addEventListener('toggleMediaReview', handler, false);
    observer.observe(document.body, { attributes: true });
    handler();

    return () => {
      window.removeEventListener('resize', handler);
      window.removeEventListener('toggleMediaReview', handler);
      observer.disconnect();
    };
  }, [height, heightRatio, reportingOutcome]);

  return {
    agRef,
    agHeight,
  };
}
