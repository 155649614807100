import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Campaign } from 'shared/types/Campaign';
import { useMutation } from '@apollo/react-hooks';
import { CampaignInfluencerUpdate } from 'components/Campaign/Influencer/Form/graphql';
import RequiredFormLabel from '@UIComponents/RequiredFormLabel/RequiredFormLabel';
import { INPUT_ERROR_CLASS } from 'components/Campaign/CampaignSetupDrawer/CampaignSetup/Details/BasicDetails/constants';
import ApplyButton from '@UIComponents/Campaign/CampaignSetup/Buttons/ApplyButton/ApplyButton';
import CancelButton from '@UIComponents/Campaign/CampaignSetup/Buttons/CancelButton/CancelButton';
import './AddOrRequestExternalReport.scss';

const validationSchema = Yup.object({
  clientReportEmbed: Yup.string().nullable(),
});

export default function AddOrRequestExternalReport({
  campaign,
  refetch,
  setEditEmbedId,
}: Readonly<{
  campaign: Campaign;
  refetch: () => void;
  setEditEmbedId: (arg0: boolean) => void;
}>) {
  const [updateCampaign] = useMutation(CampaignInfluencerUpdate);
  const onSubmit = React.useCallback(
    (values: Campaign) => {
      const variables: {
        payload: Partial<Campaign>;
        id?: number;
      } = {
        id: values?.id,
        payload: validationSchema.validateSync(values, {
          stripUnknown: true,
        }) as Partial<Campaign>,
      };

      updateCampaign({
        variables,
      }).then(() => {
        setEditEmbedId(false);
        refetch();
      });
    },
    [refetch, updateCampaign, setEditEmbedId],
  );

  return (
    <Formik<Campaign>
      validationSchema={validationSchema}
      initialValues={campaign ?? ({} as Campaign)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, getFieldProps, touched, dirty }) => (
        <Form className="external-report-tab__form" onSubmit={handleSubmit}>
          <div className="form-fields">
            <RequiredFormLabel text="DOMO Embed ID" htmlFor="clientReportEmbed">
              <input
                required
                id="clientReportEmbed"
                type="text"
                className={`campaign-setup__input ${
                  touched?.clientReportEmbed && errors?.clientReportEmbed
                    ? INPUT_ERROR_CLASS
                    : ''
                }`}
                placeholder="DOMO Embed ID"
                {...getFieldProps('clientReportEmbed')}
              />
            </RequiredFormLabel>
          </div>
          <a
            className="asana-form"
            href="https://form.asana.com/?k=2FgIHSN67oa_4uEbMSaFMg&d=395267310088080"
            target="_blank"
            rel="noreferrer"
          >
            No code? Fill out the form.
          </a>
          <div className="actions">
            <ApplyButton
              text="Embed DOMO board"
              onClick={handleSubmit}
              disabled={!dirty || Object.keys(errors ?? {}).length > 0}
            />
            {campaign?.clientReportEmbed ? (
              <CancelButton onClick={() => setEditEmbedId(false)} />
            ) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
}
