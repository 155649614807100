import React from 'react';
import { Platforms } from 'shared/types/platforms.d';
import BaseIcon from '../BaseIcon/BaseIcon';
import './PlatformIcon.scss';
import InstagramLogo from '../_assets/react_svg/platforms/InstagramLogo';
import YouTubeLogo from '../_assets/react_svg/platforms/YouTubeLogo';
import TikTokLogo from '../_assets/react_svg/platforms/TikTokLogo';
import TwitterLogo from '../_assets/react_svg/platforms/TwitterLogo';
import FacebookLogo from '../_assets/react_svg/platforms/FacebookLogo';
import TwitchLogo from '../_assets/react_svg/platforms/TwitchLogo';
import OtherLogo from '../_assets/react_svg/platforms/OtherLogo';

type PlatformComponent =
  | typeof InstagramLogo
  | typeof YouTubeLogo
  | typeof TikTokLogo
  | typeof TwitterLogo
  | typeof FacebookLogo
  | typeof TwitchLogo;

const platformMapping: { [index: string]: null | PlatformComponent } = {
  instagram: InstagramLogo,
  youtube: YouTubeLogo,
  tiktok: TikTokLogo,
  twitter: TwitterLogo,
  facebook: FacebookLogo,
  twitch: TwitchLogo,
  other: OtherLogo,
  film: null,
  season: null,
};

export type IconSize =
  | 'x-small'
  | 'small'
  | 'mid-small'
  | 'medium'
  | 'large'
  | 'huge';

type PlatformIconProps = {
  platform: keyof typeof Platforms;
  size?: IconSize;
  colorType?: 'blackWhite' | 'platform';
};

export default function PlatformIcon({
  platform,
  size = 'medium',
  colorType = 'platform',
}: PlatformIconProps) {
  const icon = platform === 'other' ? 'folder_open' : `${platform}_no_line`;
  const SvgComponent = platformMapping[platform];

  return (
    <div className={`platform-icon platform-icon-${platform}`}>
      {colorType === 'blackWhite' && SvgComponent ? (
        <SvgComponent size={size} />
      ) : (
        <BaseIcon icon={icon} size={size} />
      )}
    </div>
  );
}
