/* eslint-disable max-lines, max-statements, max-lines-per-function */
import {
  POST_MEDIA_TYPE,
  PHOTO_MEDIA_TYPE,
  STORY_MEDIA_TYPE,
  VIDEO_MEDIA_TYPE,
  STREAM_MEDIA_TYPE,
  REELS_MEDIA_TYPE,
  LIVE_MEDIA_TYPE,
  OTHER_PLATFORM,
  YOUTUBE_PLATFORM,
  TWITCH_PLATFORM,
  INSTAGRAM_PLATFORM,
  FACEBOOK_PLATFORM,
  TIKTOK_PLATFORM,
  TWITTER_PLATFORM,
  METRICS_PER_MEDIA_TYPE,
  PLACEMENT_METRICS as PLACEMENT_METRIC_TYPES,
} from 'shared/metrics/metrics';

import {
  PLATFORMS_MEDIA_TYPES,
  METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS,
} from 'constants/metrics';

import pick from 'lodash/pick';
import kebabCase from 'lodash/kebabCase';
import upperfirst from 'lodash/upperFirst';

export const getKebabMetric = (metric, joinMetric) => {
  let kebabMetric = kebabCase(metric).replace('you-tube', 'youtube');
  if (!joinMetric) {
    return kebabMetric;
  }
  const changeCasing = kebabMetric.match(/(.*)-(story|feed)-(.*)(.*)/);
  if (changeCasing) {
    kebabMetric = `${changeCasing[1]}-${
      changeCasing[2]
    }${changeCasing[3][0].toUpperCase()}${changeCasing[3].slice(1)}${
      changeCasing[4]
    }`;
  }

  return kebabMetric;
};

function getMetricTypeNameFromArrayOfMetrics(arrayOfMetrics) {
  return arrayOfMetrics.map((metric) => metric.metricType);
}

export const INFLUENCER_STATS_METRICS = {
  [`${FACEBOOK_PLATFORM}-${POST_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
  ),
  [`${TWITTER_PLATFORM}-${POST_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
  ),
  [`${INSTAGRAM_PLATFORM}-${PHOTO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[PHOTO_MEDIA_TYPE],
  ),
  [`${YOUTUBE_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${FACEBOOK_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${FACEBOOK_PLATFORM}-${LIVE_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[LIVE_MEDIA_TYPE],
  ),
  [`${TWITTER_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${INSTAGRAM_PLATFORM}-${STORY_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[STORY_MEDIA_TYPE],
  ),
  [`${INSTAGRAM_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${INSTAGRAM_PLATFORM}-${REELS_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[REELS_MEDIA_TYPE],
  ),
  [`${INSTAGRAM_PLATFORM}-${LIVE_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[LIVE_MEDIA_TYPE],
  ),
  [`${TIKTOK_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${YOUTUBE_PLATFORM}-${STREAM_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
  ),
  [`${TWITCH_PLATFORM}-${STREAM_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
  ),
  [`${OTHER_PLATFORM}-${VIDEO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[VIDEO_MEDIA_TYPE],
  ),
  [`${OTHER_PLATFORM}-${STORY_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[STORY_MEDIA_TYPE],
  ),
  [`${OTHER_PLATFORM}-${STREAM_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[STREAM_MEDIA_TYPE],
  ),
  [`${OTHER_PLATFORM}-${POST_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[POST_MEDIA_TYPE],
  ),
  [`${OTHER_PLATFORM}-${PHOTO_MEDIA_TYPE}`]: getMetricTypeNameFromArrayOfMetrics(
    METRICS_PER_MEDIA_TYPE[PHOTO_MEDIA_TYPE],
  ),
};

const EPISODE_FILM_METRICS = [
  {
    metricType: 'impressions',
    platform: 'season',
    mediaType: 'episode',
  },
  {
    metricType: 'impressions',
    platform: 'film',
    mediaType: 'film',
  },
];

const MAIN_METRICS = [
  [YOUTUBE_PLATFORM, VIDEO_MEDIA_TYPE],
  [YOUTUBE_PLATFORM, STREAM_MEDIA_TYPE],
  [FACEBOOK_PLATFORM, VIDEO_MEDIA_TYPE],
  [FACEBOOK_PLATFORM, POST_MEDIA_TYPE],
  [FACEBOOK_PLATFORM, LIVE_MEDIA_TYPE],
  [TWITCH_PLATFORM, STREAM_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM, STORY_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM, PHOTO_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM, VIDEO_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM, REELS_MEDIA_TYPE],
  [INSTAGRAM_PLATFORM, LIVE_MEDIA_TYPE],
  [TIKTOK_PLATFORM, VIDEO_MEDIA_TYPE],
  [TWITTER_PLATFORM, POST_MEDIA_TYPE],
  [TWITTER_PLATFORM, VIDEO_MEDIA_TYPE],
  [OTHER_PLATFORM, POST_MEDIA_TYPE, { showLimitedMetricDetails: true }],
  [OTHER_PLATFORM, PHOTO_MEDIA_TYPE, { showLimitedMetricDetails: true }],
  [OTHER_PLATFORM, STORY_MEDIA_TYPE, { showLimitedMetricDetails: true }],
  [OTHER_PLATFORM, VIDEO_MEDIA_TYPE, { showLimitedMetricDetails: true }],
  [OTHER_PLATFORM, STREAM_MEDIA_TYPE, { showLimitedMetricDetails: true }],
];

export const AVAILABLE_METRICS = [
  ...MAIN_METRICS.reduce((acc, [platform, mediaType, additionalData = {}]) => {
    METRICS_PER_MEDIA_TYPE[mediaType].forEach(
      ({
        metricType,
        format,
        formatter,
        allowedPlatforms,
        notAllowedPlatforms,
      }) => {
        if (allowedPlatforms?.length && !allowedPlatforms.includes(platform)) {
          return;
        }
        if (
          notAllowedPlatforms?.length &&
          notAllowedPlatforms.includes(platform)
        ) {
          return;
        }

        const existingIndex = acc.findIndex(
          (e) =>
            e.platform === platform &&
            e.mediaType === mediaType &&
            e.metricType === metricType,
        );
        if (existingIndex !== -1) {
          return;
        }
        acc.push({
          metricType,
          platform,
          mediaType,
          format,
          formatter,
          ...additionalData,
        });
      },
    );
    return acc;
  }, EPISODE_FILM_METRICS),
];

export const AVAILABLE_METRICS_WITH_DEMOGRAPHICS = MAIN_METRICS.reduce(
  (acc, [platform, mediaType, additionalData = {}]) => {
    METRICS_PER_MEDIA_TYPE_WITH_DEMOGRAPHICS[mediaType].forEach(
      ({
        metricType,
        format,
        formatter,
        allowedPlatforms,
        notAllowedPlatforms,
      }) => {
        if (allowedPlatforms?.length && !allowedPlatforms.includes(platform)) {
          return;
        }
        if (
          notAllowedPlatforms?.length &&
          notAllowedPlatforms.includes(platform)
        ) {
          return;
        }

        const existingIndex = acc.findIndex(
          (e) =>
            e.platform === platform &&
            e.mediaType === mediaType &&
            e.metricType === metricType,
        );
        if (existingIndex !== -1) {
          return;
        }
        const metric = {
          metricType,
          platform,
          mediaType,
          format,
          formatter,
          ...additionalData,
        };

        acc.push(metric);
      },
    );
    return acc;
  },
  EPISODE_FILM_METRICS,
);

const KPI_METRICS = AVAILABLE_METRICS.filter((metric) =>
  [
    'impressions',
    'views',
    'ccv',
    'clicks',
    'conversions',
    'contentPieces',
  ].includes(metric.metricType),
);

const buildMetrics = (metricsToParse, isTraditional) =>
  metricsToParse.reduce(
    (acc, { platform, mediaType, metricType, showLimitedMetricDetails }) => {
      if (isTraditional && PLACEMENT_METRIC_TYPES.includes(metricType)) {
        return acc;
      }
      const keyParts = [platform, mediaType, metricType];
      const metricKey = keyParts.join('-');
      const noSpaceMetric = metricKey.replace(/\s/g, '');
      let metricLabel = keyParts.map((key) => upperfirst(key)).join(' ');
      if (platform === 'film' || platform === 'season') {
        metricLabel = `${upperfirst(mediaType)} ${upperfirst(metricType)}`;
      }
      metricLabel = metricLabel.replace(/ccv$/i, 'CCV');
      metricLabel = metricLabel.replace(/contentPieces/i, 'Content Pieces');

      acc.push({
        id: `${metricKey}_value`,
        label: metricLabel,
        field: noSpaceMetric,
        overIsGood: true,
        type: 'number',
        precision: 2,
        format: '0[.]0a',
        platform,
      });

      if (showLimitedMetricDetails) {
        return acc;
      }

      return acc.concat(
        {
          id: `${metricKey}_cpx`,
          label: `${metricLabel}: Cost per`,
          field: `target${noSpaceMetric}CPX`,
          overIsGood: false,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          platform,
        },
        {
          id: `${metricKey}_fpx`,
          label: `${metricLabel}: COGS per`,
          field: `target${noSpaceMetric}FPX`,
          overIsGood: false,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          platform,
        },
        {
          id: `${metricKey}_ppx`,
          label: `${metricLabel}: Profit per`,
          field: `target${noSpaceMetric}PPX`,
          overIsGood: true,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          platform,
        },
        {
          id: `${metricKey}_allocation`,
          label: `${metricLabel}: Total Cost`,
          field: `target${noSpaceMetric}Allocation`,
          overIsGood: false,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          platform,
        },
        {
          id: `${metricKey}_cogs`,
          label: `${metricLabel}: Total COGS`,
          field: `target${noSpaceMetric}Fees`,
          overIsGood: false,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          platform,
        },
        {
          id: `${metricKey}_profit`,
          label: `${metricLabel}: Total Profit`,
          field: `target${noSpaceMetric}Profit`,
          overIsGood: true,
          type: 'number',
          precision: 2,
          format: '0,0[.]00a',
          inputMaskedFormat: '0,0[.]00a',
          readOnly: true,
          platform,
        },
        {
          id: `${metricKey}_margin`,
          label: `${metricLabel}: Total Margin`,
          field: `target${noSpaceMetric}Margin`,
          overIsGood: true,
          type: 'percentage',
          icon: 'percentage',
          precision: 2,
          format: '0[.]00%',
          inputMaskedFormat: '0,0[.]00',
          readOnly: true,
          platform,
        },
      );
    },
    [],
  );

export const METRICS_DISPLAY = [
  {
    id: 'allocation',
    label: 'Allocations',
    field: 'targetAllocations',
    overIsGood: true,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'cogs',
    label: 'COGS',
    field: 'targetCogs',
    overIsGood: false,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'margin',
    label: 'Margin',
    field: 'targetMargin',
    overIsGood: true,
    type: 'percentage',
    icon: 'percentage',
    targetable: true,
    format: '0[.]00%',
    inputMaskedFormat: '0[.]00',
    readOnly: true,
  },
  ...buildMetrics(AVAILABLE_METRICS),
];

export const KPI_METRICS_DISPLAY = [
  {
    id: 'allocation',
    label: 'Allocations',
    field: 'targetAllocations',
    overIsGood: true,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'cogs',
    label: 'COGS',
    field: 'targetCogs',
    overIsGood: false,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'margin',
    label: 'Margin',
    field: 'targetMargin',
    overIsGood: true,
    type: 'percentage',
    icon: 'percentage',
    targetable: true,
    format: '0[.]00%',
    inputMaskedFormat: '0[.]00',
    readOnly: true,
  },
  ...buildMetrics(KPI_METRICS),
];

export const TRADITIONAL_IMPRESSIONS_METRICS = [
  {
    id: 'allocation',
    label: 'Allocations',
    field: 'targetAllocations',
    overIsGood: true,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'cogs',
    label: 'COGS',
    field: 'targetCogs',
    overIsGood: false,
    type: 'number',
    icon: 'allocated',
    targetable: true,
    format: '0[.]0a',
    inputMaskedFormat: '0,0[.]00',
    readOnly: true,
  },
  {
    id: 'margin',
    label: 'Margin',
    field: 'targetMargin',
    overIsGood: true,
    type: 'percentage',
    icon: 'percentage',
    targetable: true,
    format: '0[.]00%',
    inputMaskedFormat: '0[.]00',
    readOnly: true,
  },
  {
    id: 'traditional-impressions_value',
    label: 'Impressions',
    field: 'impressions',
    overIsGood: true,
    type: 'number',
    precision: 2,
    format: '0[.]0a',
    readOnly: true,
    prefixesToGroup: [
      ...PLATFORMS_MEDIA_TYPES[OTHER_PLATFORM].map(
        (mediaType) => `other-${mediaType}`,
      ),
      'season-episode',
      'film-film',
    ].map((prefix) => `${prefix}-impressions_value`),
  },
  {
    id: 'traditional-impressions_cpx',
    label: 'Impressions: Cost per',
    field: 'impressions_cpx',
    overIsGood: false,
    type: 'number',
    precision: 2,
    format: '0,0[.]00a',
    inputMaskedFormat: '0,0[.]00a',
    readOnly: true,
    prefixesToGroup: [
      ...PLATFORMS_MEDIA_TYPES[OTHER_PLATFORM].map(
        (mediaType) => `other-${mediaType}`,
      ),
      'season-episode',
      'film-film',
    ].map((prefix) => `${prefix}-impressions_cpx`),
  },
  ...buildMetrics(KPI_METRICS, true),
];

export const METRICS_BY_ID = TRADITIONAL_IMPRESSIONS_METRICS.reduce(
  (acc, item) => {
    acc[item.id] = item;
    return acc;
  },
  {},
);

export const METRICS_MAP = METRICS_DISPLAY.reduce((acc, item) => {
  acc[item.id] = item.field;

  return acc;
}, {});

export const METRICS_MAP_BY_FIELD = METRICS_DISPLAY.reduce((acc, item) => {
  acc[item.field] = item;

  return acc;
}, {});

export const METRICS_BY_FIELD = METRICS_DISPLAY.reduce((acc, item) => {
  acc[item.field] = item.label;

  return acc;
}, {});

export const METRICS_VALUES = METRICS_DISPLAY.map((metric) => metric.id);

export const TARGETABLE_METRICS = AVAILABLE_METRICS;

function getMetricTypeLabel(platform, mediaType, metricType) {
  let metricTypeToUse = metricType;
  if (metricType === 'contentPieces') {
    metricTypeToUse = 'content Pieces';
  }
  if (metricType === 'ccv') {
    metricTypeToUse = 'CCV';
  }
  return `${upperfirst(platform)} ${upperfirst(mediaType)} ${upperfirst(
    metricTypeToUse,
  )}`;
}

const getSingular = (metricType) => {
  const lowerCased = metricType.toLowerCase();

  if (lowerCased.slice(-1) === 's') {
    return lowerCased.slice(0, -1);
  }
  return lowerCased;
};

export const METRIC_TARGETS = AVAILABLE_METRICS.map(
  ({ platform, mediaType, metricType, format, formatter }) => {
    const keyParts = [platform, mediaType, metricType];
    return {
      value: keyParts.join('-'),
      label: getMetricTypeLabel(platform, mediaType, metricType),
      singular: getSingular(metricType),
      format,
      formatter,
      platform,
      mediaType,
      metricType,
      defaultValues: {
        value: null,
        cpx: null,
        fpx: null,
        ppx: null,
        allocation: null,
        cogs: null,
        profit: null,
        prediction: null,
        metricType,
      },
    };
  },
);

export const METRIC_TARGETS_WITH_DEMOGRAPHICS = AVAILABLE_METRICS_WITH_DEMOGRAPHICS.map(
  ({ platform, mediaType, metricType, format, formatter }) => {
    const keyParts = [platform, mediaType, metricType];
    return {
      value: keyParts.join('-'),
      label: getMetricTypeLabel(platform, mediaType, metricType),
      singular: getSingular(metricType),
      format,
      formatter,
      platform,
      mediaType,
      metricType,
      defaultValues: {
        value: null,
        cpx: null,
        fpx: null,
        ppx: null,
        allocation: null,
        cogs: null,
        profit: null,
        prediction: null,
        metricType,
      },
    };
  },
);

export const getMetricTarget = ({ platform, mediaType, metricType }) =>
  METRIC_TARGETS.find(
    ({ value }) => value === `${platform}-${mediaType}-${metricType}`,
  );

export const METRIC_TARGETS_OPTIMIZER = [
  'Views',
  'Clicks',
  'Conversions',
  'CTR',
  'VPC',
].map((metric) => {
  const kebabMetric = getKebabMetric(metric, true);

  return {
    value: kebabMetric,
    label: metric,
    singular: getSingular(metric),
    defaultValues: {
      value: null,
      cpx: null,
      fpx: null,
      ppx: null,
      allocation: null,
      cogs: null,
      profit: null,
      prediction: null,
      metricType: kebabMetric,
    },
    defaultValuesMetricManual: {
      value: null,
      networkId: null,
      liveDate: null,
      expireOnDate: null,
      expireOnActual: false,
      metricType: kebabMetric,
    },
  };
});

export const METRIC_TARGETS_VALUES = METRIC_TARGETS.map((t) => t.value);
export const METRIC_TARGETS_VALUES_OPTIMIZER = METRIC_TARGETS_OPTIMIZER.map(
  (t) => t.value,
);

export const METRIC_TARGETS_LABELS = METRIC_TARGETS.reduce(
  (acc, item) => ({
    ...acc,
    [`${item.value}_value`]: item.label,
  }),
  {},
);

const METRICS_INDEXES_BY_ID = {};
METRICS_DISPLAY.forEach((m, index) => {
  METRICS_INDEXES_BY_ID[m.id] = index;
});

export { METRICS_INDEXES_BY_ID };

export const DEFAULT_METRICS_INDEXES_BY_ID = pick(METRICS_INDEXES_BY_ID, [
  'allocation',
  'cogs',
  'margin',
]);

export const PLACEMENT_COLUMNS = [
  'season-episode-impressions_value',
  'film-film-impressions_value',
  'youtube-video-impressions_value',
  'youtube-video-clicks_value',
  'youtube-video-conversions_value',
  'youtube-video-views_value',
  'youtube-video-likes_value',
  'youtube-video-comments_value',
  'facebook-post-impressions_value',
  'facebook-post-likes_value',
  'facebook-live-views_value',
  'twitter-post-impressions_value',
  'twitter-post-likes_value',
  'twitter-post-clicks_value',
  'twitter-post-conversions_value',
  'twitch-story-impressions_value',
  'twitch-story-conversions_value',
  'twitch-story-views_value',
  'instagram-story-impressions_value',
  'instagram-story-views_value',
  'instagram-reels-views_value',
  'instagram-live-views_value',
  'instagram-video-likes_value',
  'instagram-video-views_value',
];

export const PLACEMENT_METRICS_INDEXES_BY_ID = pick(
  METRICS_INDEXES_BY_ID,
  PLACEMENT_COLUMNS,
);

export const DEFAULT_PLACEMENT_METRICS_INDEXES_BY_ID = pick(
  METRICS_INDEXES_BY_ID,
  PLACEMENT_COLUMNS,
);

export const PLACEMENT_PRIMARY_KPI = {
  youtube: 'youtube-views',
  instagram: 'instagram-views',
  season: 'impressions',
  twitter: 'twitter-impressions',
  film: 'impressions',
  twitch: 'twitch-views',
};

export const PRODUCTION_VIEW_METRICS = [
  ...['impressions', 'averageImpressions'].map((metricType) => ({
    metricType,
    platform: 'season',
    mediaType: '*',
    style: '*',
  })),

  ...['impressions', 'averageImpressions'].map((metricType) => ({
    metricType,
    platform: 'film',
    mediaType: '*',
    style: '*',
  })),

  ...[
    'impressions',
    'likes',
    'ctr',
    'engagementRate',
    'averageViews',
    'subscribers',
    'views',
    'vpc',
  ].map((metricType) => ({
    metricType,
    platform: 'youtube',
    mediaType: '*',
    style: '*',
  })),

  ...['impressions', 'likes'].map((metricType) => ({
    metricType,
    platform: 'facebook',
    mediaType: '*',
    style: '*',
  })),

  ...['impressions', 'likes', 'followers'].map((metricType) => ({
    metricType,
    platform: 'twitter',
    mediaType: '*',
    style: '*',
  })),

  ...[
    'averageViews',
    'followers',
    'views',
    'subscribers',
    'liveViews',
    'uniqueVisitors',
    'avgConcurrents',
    'maxConcurrents',
    'viewDuration',
    'minutesStreamed',
    'chatVolume',
  ].map((metricType) => ({
    metricType,
    platform: 'twitch',
    mediaType: '*',
    style: '*',
  })),

  ...[
    'impressions',
    'botPercentage',
    'ctr',
    'followers',
    'views',
    'vpc',
    'averageEngagement',
  ].map((metricType) => ({
    metricType,
    platform: 'instagram',
    mediaType: '*',
    style: '*',
  })),
];

export const PRODUCTION_EXTERNAL_VIEW_METRICS = [
  {
    platform: 'season',
    mediaType: 'episode',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'season',
    mediaType: 'episode',
    style: null,
    metricType: 'averageImpressions',
  },
  {
    platform: 'film',
    mediaType: 'film',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'film',
    mediaType: 'film',
    style: null,
    metricType: 'averageImpressions',
  },
  {
    platform: 'youtube',
    mediaType: 'production',
    style: null,
    metricType: 'subscribers',
    reportingWindow: 'allTime',
  },
  {
    platform: 'youtube',
    mediaType: 'video',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'youtube',
    mediaType: 'video',
    style: null,
    metricType: 'likes',
  },
  {
    platform: 'youtube',
    mediaType: 'video',
    style: null,
    metricType: 'averageViews',
  },
  {
    platform: 'youtube',
    mediaType: 'video',
    style: null,
    metricType: 'subscribers',
  },
  {
    platform: 'youtube',
    mediaType: 'video',
    style: null,
    metricType: 'views',
  },
  {
    platform: 'facebook',
    mediaType: 'production',
    style: null,
    metricType: 'likes',
    reportingWindow: 'allTime',
  },
  {
    platform: 'facebook',
    mediaType: 'video',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'facebook',
    mediaType: 'video',
    style: null,
    metricType: 'likes',
  },
  {
    platform: 'facebook',
    mediaType: 'post',
    style: null,
    metricType: 'likes',
    reportingWindow: 'live30',
  },
  {
    platform: 'facebook',
    mediaType: 'post',
    style: null,
    metricType: 'impressions',
    reportingWindow: 'live30',
  },
  {
    platform: 'facebook',
    mediaType: 'production',
    style: null,
    metricType: 'followers',
    reportingWindow: 'allTime',
  },
  {
    platform: 'twitter',
    mediaType: 'production',
    style: null,
    metricType: 'followers',
    reportingWindow: 'allTime',
  },
  {
    platform: 'twitter',
    mediaType: 'post',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'twitch',
    mediaType: 'production',
    style: null,
    metricType: 'followers',
    reportingWindow: 'allTime',
  },
  {
    platform: 'instagram',
    mediaType: 'production',
    style: null,
    metricType: 'followers',
    reportingWindow: 'allTime',
  },
  {
    platform: 'instagram',
    mediaType: 'story',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'instagram',
    mediaType: 'post',
    style: null,
    metricType: 'impressions',
  },
  {
    platform: 'instagram',
    mediaType: 'video',
    style: null,
    metricType: 'followers',
  },
];
