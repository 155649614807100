import React from 'react';
import { ColDef } from 'ag-grid-community';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Loader from 'components/UI/Loader/Loader';
import DataGrid from 'components/DataGrid/DataGrid';
import { INFLUENCER_MEDIA_CONFIG } from 'constants/metrics';
import ApplicationContext from 'components/Application/Context';
import useSegmentReportingTracking from 'utils/segment/useSegmentReportingTracking';
import PerformanceMenu from 'external/components/Performance/PerformanceMenu/PerformanceMenu';
import { reportingWindowsMetaData } from 'shared/campaigns/creatorCampaigns/reportingWindows';
import useCreatorColumns from 'components/Campaign/Influencer/View/hooks/creatorColumnsHooks/useCreatorColumns';
import usePlacementsLoader from 'components/Campaign/Influencer/View/Stats/Performance/hooks/usePlacementsLoader';

import CampaignInfo from './graphql/CampaignInfo.graphql';

import './Performance.scss';

export default function Performance({ campaignId }: { campaignId: number }) {
  const { user } = React.useContext(ApplicationContext);
  const apolloClient = useApolloClient();

  const { loading: campaignLoading, data } = useQuery(CampaignInfo, {
    variables: {
      campaignId,
      mediaConfig: INFLUENCER_MEDIA_CONFIG,
      metricTypes: ['*'],
      tab: 'performance',
    },
  });

  const [reportingWindow, setReportingWindow] = React.useState<string | null>(
    null,
  );

  React.useEffect(() => {
    if (reportingWindow || !data?.campaign?.creatorReportingWindows?.length) {
      return;
    }

    /*
     * Find first reporting window from the main array.
     */
    const firstMatchForReportingWindow = Object.keys(
      reportingWindowsMetaData,
    ).find((r) => data.campaign.creatorReportingWindows.includes(r));
    setReportingWindow(firstMatchForReportingWindow ?? null);
  }, [
    data?.campaign?.creatorReportingWindows,
    setReportingWindow,
    reportingWindow,
  ]);

  const { gridApi, setGridApi, loading } = usePlacementsLoader({
    id: campaignId,
    reportingWindow,
    campaignStartDate: data?.campaign?.dateRangeFrom,
    columns: data?.campaign?.creatorCampaignsColumns?.performance?.columns,
  });

  const reportingOutcome = React.useMemo(
    () => user?.amplitudeExperiments?.reportingOutcome ?? false,
    [user?.amplitudeExperiments?.reportingOutcome],
  );

  const columnDefs: ColDef[] = useCreatorColumns(
    'performance',
    data?.campaign?.creatorCampaignsColumns?.performance?.columns ?? {},
    true,
    true,
  );

  const trackReportingSegmentEvent = useSegmentReportingTracking(
    data?.campaign,
  );

  if (campaignLoading) {
    return <Loader />;
  }

  if (!columnDefs?.length) {
    return null;
  }

  return (
    <section className="campaign-performance-grid">
      <PerformanceMenu
        reportingWindow={reportingWindow}
        setReportingWindow={setReportingWindow}
        trackSegmentEvent={trackReportingSegmentEvent}
        campaign={data?.campaign ?? null}
      />
      <DataGrid
        className="performance-grid"
        columnDefs={columnDefs ?? ([] as ColDef[])}
        gridApi={gridApi ?? undefined}
        setGridApi={setGridApi}
        loading={loading ?? false}
        columnState={null}
        setColumnState={() => null}
        reportingOutcome={reportingOutcome}
        height="adaptative"
        showPagination={false}
        loadingOverlayComponent="customLoadingOverlay"
        campaignSubType={data?.campaign?.subType}
        context={{
          isExternal: true,
          reportingWindow,
          campaign: data?.campaign,
          apolloClient,
          tab: 'stats',
        }}
        sideBar={false}
      />
    </section>
  );
}
