import UIIcon from 'components/UI/Icon/Icon';

const cbn = 'data-grid-cell-label-with-icon';

export default function LabelWithIcon({
  label,
  icon,
  tooltip,
  dataCy,
}: Readonly<{
  label: string;
  icon: string;
  tooltip: string;
  dataCy: string;
}>) {
  return (
    <div className={cbn} data-cy={dataCy}>
      <UIIcon type={icon} size="small" tooltip={tooltip} />
      <span>{label}</span>
    </div>
  );
}
