import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ClientAccessTabs } from 'components/Campaign/types';

import './Tabs.scss';

const cbn = 'campaigns__tab';

type PermissionsByTab = {
  [x: string]: (clientAccessTabs: ClientAccessTabs) => boolean;
};

export const permissionsByTab: PermissionsByTab = {
  creators: (clientAccessTabs) =>
    clientAccessTabs.listView || clientAccessTabs.listReview,
  posts: (clientAccessTabs) =>
    clientAccessTabs.contentView || clientAccessTabs.contentReview,
  summary: (clientAccessTabs) => clientAccessTabs.campaignSummaryView,
  insights: (clientAccessTabs) => clientAccessTabs.reporting,
  performance: (clientAccessTabs) => clientAccessTabs.performance,
};

export type CampaignTabsPath =
  | 'summary'
  | 'brief'
  | 'creators'
  | 'posts'
  | 'insights'
  | 'performance';

type CampaignTabDefinition = {
  path: CampaignTabsPath;
  label: string;
};

const tabs: CampaignTabDefinition[] = [
  { path: 'summary', label: 'Campaign Summary' },
  { path: 'brief', label: 'Brief' },
  { path: 'creators', label: 'Creators' },
  { path: 'posts', label: 'Content' },
  { path: 'insights', label: 'Insights' },
  { path: 'performance', label: 'Performance' },
];

const can = (clientAccessTabs: ClientAccessTabs, path: CampaignTabsPath) =>
  permissionsByTab[path] && permissionsByTab[path](clientAccessTabs);

type ExternalCampaignTabsProps = {
  clientAccessTabs?: ClientAccessTabs;
  active: string;
  campaignId?: number;
};

function ExternalCampaignTabs({
  active,
  clientAccessTabs,
  campaignId,
}: ExternalCampaignTabsProps) {
  if (!clientAccessTabs || !campaignId) return null;

  return (
    <ul className={`${cbn}__list`}>
      {tabs.map(
        ({ path, label }) =>
          can(clientAccessTabs, path) && (
            <li
              key={path}
              className={`${cbn}__list-item`}
              data-cy={`${cbn}-${path}`}
            >
              <Link
                className={classnames(cbn, {
                  [`${cbn}--active`]: active === path,
                })}
                to={`/campaigns/${campaignId}/${path}`}
              >
                {label}
              </Link>
            </li>
          ),
      )}
    </ul>
  );
}

export default ExternalCampaignTabs;
