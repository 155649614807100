import BaseButton from '@UIComponents/BaseButton/BaseButton';
import { PlatformLabel } from 'external/components/constants';

import './ViewContentButton.scss';

type ViewContentButtonProps = {
  platform: string;
  url: string;
};

function ViewContentButton({ platform, url }: ViewContentButtonProps) {
  return (
    <div className="ViewContentButton">
      <BaseButton
        variant="link"
        size="large"
        text={`View ${PlatformLabel[platform]} content`}
        icon={`${platform}_no_line`}
        href={url}
        target="_blank"
      />
    </div>
  );
}

export default ViewContentButton;
