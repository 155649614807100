export const GENDER_MALE = {
  metricType: 'genderMale',
  format: '0.[00]%',
  formatter: 'percent',
};

export const GENDER_FEMALE = {
  metricType: 'genderFemale',
  format: '0.[00]%',
  formatter: 'percent',
};

export const GENDER_NON_BINARY = {
  metricType: 'genderNonBinary',
  format: '0.[00]%',
  formatter: 'percent',
};

export const AGE_13_17 = {
  metricType: 'age13_17',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_18_24 = {
  metricType: 'age18_24',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_25_34 = {
  metricType: 'age25_34',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_35_44 = {
  metricType: 'age35_44',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_45_54 = {
  metricType: 'age45_54',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_55_64 = {
  metricType: 'age55_64',
  format: '0.[00]%',
  formatter: 'percent',
};
export const AGE_65 = {
  metricType: 'age65',
  format: '0.[00]%',
  formatter: 'percent',
};

export const DEMOGRAPHIC_METRICS = [
  GENDER_MALE,
  GENDER_FEMALE,
  AGE_13_17,
  AGE_18_24,
  AGE_25_34,
  AGE_35_44,
  AGE_45_54,
  AGE_55_64,
  AGE_65,
];

export const DEMOGRAPHIC_METRICS_FIELDS = DEMOGRAPHIC_METRICS.map(
  (m) => m.metricType,
);
