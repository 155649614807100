import BaseCard from '@UIComponents/BaseCard/BaseCard';
import './PlatformMetrics.scss';
import { nFormatter } from 'utils/nFormatter';

type PlatformMetricsProps = {
  data: {
    views: number;
    clicks: number;
  };
  permissions: {
    clickTracking?: boolean;
  };
};

function PlatformMetrics({ data, permissions }: PlatformMetricsProps) {
  const showTotalViews = nFormatter(data.views);
  const showTotalClicks = permissions?.clickTracking
    ? nFormatter(data?.clicks)
    : null;

  return (
    <div className="PlatformMetrics">
      {/* @ts-ignore */}
      <BaseCard
        title="Total views"
        titleType="boxed"
        inlineBlock
        dark
        titleAlign="left"
        titleIcon="eye"
        titleIconColor="white"
      >
        <div className="numbers-display">{showTotalViews}</div>
      </BaseCard>

      {/* @ts-ignore */}
      <BaseCard
        title="Total clicks"
        titleType="boxed"
        inlineBlock
        dark
        titleAlign="left"
        titleIcon="mouse"
        titleIconColor="white"
      >
        <div className="numbers-display">{showTotalClicks}</div>
      </BaseCard>
    </div>
  );
}

export default PlatformMetrics;
