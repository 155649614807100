import React, { useContext, useEffect } from 'react';
import { useSubscription } from '@apollo/react-hooks';
import { useNavigate, useParams } from 'react-router-dom';

import DocumentTitle from 'components/DocumentTitle/DocumentTitle';
import UIIcon from 'components/UI/Icon/Icon';
import UILink from 'components/UI/Link/Link';

import DeliverableList from 'external/components/Deliverable/List/List';
import PlacementsList from 'external/components/Placement/List/List';
import CampaignSummary from 'external/components/CampaignSummary/CampaignSummary';
import { ExternalApplicationContext } from 'external/components/Application/Context';
import usePermissions from 'store/authentication/usePermissions';
import { ClientAccessTabs } from 'components/Campaign/types';
import NoDataEmptyState from 'external/components/shared/NoDataEmptyState';
import DomoIframe from 'components/Campaign/Influencer/View/DomoIframe/DomoIframe';
import Loading from '@UIComponents/Loading/Loading';
import ApplicationContext from 'components/Application/Context';
import Performance from 'external/components/Performance/Performance';

import ExternalCampaignTabs, {
  permissionsByTab,
} from 'external/components/Campaign/Tabs/Tabs';

import ExternalDeliverableSubscription from '../graphql/externalDeliverableSubscription';

import './View.scss';

type TabsProps = {
  // @ts-ignore
  [tab: string]: React.ComponentType<any>;
};

const tabs = {
  creators: DeliverableList,
  posts: PlacementsList,
  'content-review': PlacementsList,
  summary: CampaignSummary,
  insights: DomoIframe,
  performance: Performance,
};

const hasPermission = (tab?: string, clientAccessTabs?: ClientAccessTabs) => {
  if (!tab) {
    return false;
  }

  if (!clientAccessTabs) return false;

  if (tab === 'brief') {
    return clientAccessTabs && Object.values(clientAccessTabs).some(Boolean);
  }

  const hasAccess = permissionsByTab[tab] || (() => true);

  return hasAccess(clientAccessTabs);
};

function ExternalScreensCampaignView() {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { campaign, campaignId, initialTab } = useContext(
    ExternalApplicationContext,
  );
  const { user } = React.useContext(ApplicationContext);

  const featureIsEnabled = campaign?.clientAccess;

  const clientAccessTabsWithPerformance = React.useMemo(() => {
    if (!campaign?.clientAccessTabs) {
      return campaign?.clientAccessTabs;
    }

    return {
      ...campaign?.clientAccessTabs,
      performance:
        (user?.amplitudeExperiments?.reportingOutcome &&
          campaign?.clientAccessTabs?.performance) ??
        false,
    };
  }, [
    campaign?.clientAccessTabs,
    user?.amplitudeExperiments?.reportingOutcome,
  ]);

  useSubscription(ExternalDeliverableSubscription, {
    onSubscriptionData: async () => {
      // TODO: Implement alert modal.
    },
    variables: { campaignId, executionStatusPhase: ['list'] },
    shouldResubscribe: true,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (
      campaign &&
      campaignId &&
      (!hasPermission(tab, clientAccessTabsWithPerformance) ||
        !featureIsEnabled)
    ) {
      navigate(
        initialTab
          ? `/campaigns/${campaignId}/${initialTab}`
          : `/campaigns/${campaignId}`,
      );
    }
  }, [
    campaign,
    campaignId,
    initialTab,
    tab,
    featureIsEnabled,
    navigate,
    clientAccessTabsWithPerformance,
  ]);

  const ViewComponent =
    hasPermission(tab, clientAccessTabsWithPerformance) &&
    (tabs as TabsProps)[tab ?? 0];

  const title = campaign?.projectName || 'Campaigns';
  const campaignPermissions = usePermissions('campaigns');
  const privilegedControls = campaignPermissions?.privilegedControls;

  const componentToRender = React.useMemo(() => {
    if (campaign?.clientAccessTabs) {
      // @ts-ignore
      if (ViewComponent && featureIsEnabled) {
        return <ViewComponent key={campaign?.id} campaignId={campaign?.id} />;
      }

      return <NoDataEmptyState />;
    }

    return <Loading />;
  }, [
    ViewComponent,
    campaign?.clientAccessTabs,
    campaign?.id,
    featureIsEnabled,
  ]);

  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <div className="external-content__header__wrapper">
        <div className="external-content__title__wrapper">
          <h1 className="external-content__title">{campaign?.projectName}</h1>
          {privilegedControls && campaignId && (
            <UILink
              href={`/team/influencer/campaigns/${campaignId}/edit`}
              target="_blank"
              className="external-content-title__internal-link"
            >
              <UIIcon
                icon="exit_to_app"
                tooltip="Open in internal app"
                size="mid-small"
              />
            </UILink>
          )}
        </div>
        <div className="external-content__subsection">
          <ExternalCampaignTabs
            active={tab}
            clientAccessTabs={
              campaign?.clientAccess
                ? clientAccessTabsWithPerformance
                : undefined
            }
            campaignId={campaign?.id}
          />
        </div>
      </div>

      {componentToRender}
    </>
  );
}

export default ExternalScreensCampaignView;
