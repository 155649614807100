import React from 'react';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import ApplicationContext from 'components/Application/Context';
import { Property } from 'components/Property/types';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';

import './StarRating.scss';

const RATINGS = [
  'Not valuable at all',
  'A little valuable',
  'Somewhat valuable',
  'Very valuable',
];

function validateSegmentData(
  property?: Property,
  campaignId?: number,
  userId?: number,
  platform?: string,
  section?: string,
) {
  return (
    property?.id &&
    property?.name &&
    campaignId &&
    userId &&
    platform &&
    section
  );
}

export default function StarRating({
  id = 'rating',
  rating,
  sent,
  onClick,
  isOverlay,
  tab,
  property,
  userId,
  campaignId,
  platform,
  section,
  ratingQuestion,
  ratingMap = RATINGS,
}: {
  id?: string;
  rating?: number;
  sent?: boolean;
  onClick?: (arg0: number) => void | null;
  isOverlay?: boolean;
  tab?: string;
  property?: Property;
  userId?: number;
  campaignId?: number;
  platform?: string;
  section?: string;
  ratingQuestion?: string;
  ratingMap?: string[];
}) {
  const { segment } = React.useContext(ApplicationContext);
  const [localRating, setLocalRating] = React.useState(0);

  React.useEffect(() => {
    if (rating !== undefined) {
      setLocalRating(rating);
    }
  }, [rating]);

  const rateFn = React.useCallback(
    (ratingIndex: number) => {
      if (onClick) {
        onClick(ratingIndex);
        return;
      }

      setLocalRating(ratingIndex);

      const isSegmentDataValid = validateSegmentData(
        property,
        campaignId,
        userId,
        platform,
        section,
      );

      if (!isSegmentDataValid) {
        return;
      }

      segment.track(
        SegmentEvents.CreatorProfile,
        {
          action: SegmentActions.CardSectionRating,
          tab,
          platform,
          section,
          rating: ratingIndex,

          property: {
            id: property?.id,
            name: property?.name,
          },
        },
        {
          context: { groupId: formatCampaignId(campaignId) },
          userId: formatUserId(userId),
        },
      );
    },
    [onClick, campaignId, property, platform, segment, section, tab, userId],
  );

  return (
    <div
      className={`user-feedback__star-rating ${isOverlay ? ' is-overlay' : ''}`}
    >
      <label htmlFor="rating">
        <p>
          {ratingQuestion ?? 'How valuable would this information be to you?'}
        </p>
        <div
          id={id}
          style={{
            display: 'inline-flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {RATINGS.map((ratingElement, index) => (
            <button
              disabled={sent || Boolean(localRating)}
              key={ratingElement}
              type="button"
              onClick={() => rateFn(index + 1)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                <path
                  d="m16.15 37.75 7.85-4.7 7.85 4.75-2.1-8.9 6.9-6-9.1-.8L24 13.7l-3.55 8.35-9.1.8 6.9 6ZM11.65 44l3.25-14.05L4 20.5l14.4-1.25L24 6l5.6 13.25L44 20.5l-10.9 9.45L36.35 44 24 36.55ZM24 26.25Z"
                  fill={index + 1 > localRating ? '#d4d2e2' : '#8983b1'}
                />
              </svg>
              <p
                style={{
                  color: localRating === index + 1 ? '#8983b1' : '#d4d2e2',
                }}
              >
                {ratingMap[index]}
              </p>
            </button>
          ))}
        </div>
      </label>
    </div>
  );
}
