import React from 'react';
import InnerGrid from 'components/DataGrid/InnerGrid';
import {
  DetailGridInfo,
  IServerSideDatasource,
  ColDef,
  RowNode,
  ToolPanelDef,
  ColumnState,
  GridReadyEvent,
  ServerSideStoreType,
  Component,
  SideBarDef,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { Deliverable } from 'components/Deliverable/types';
import { loadSavedState } from './utils/savedState';
import Pagination from './Pagination/Pagination';
import './DataGrid.scss';

const cbn = 'data-grid';

export default function DataGrid({
  context = null,
  defaultColDef = {},
  components = {},
  rowClassRules = {},
  height = undefined,
  heightRatio = 4,
  loading = false,
  loadingOverlayComponent = 'customLoadingOverlay',
  onDataChange = () => {},
  renderPaginationActions = () => null,
  rowData = [],
  rowHeight,
  rowSelection = 'multiple',
  setColumnState = () => null,
  showPagination = true,
  singleClickEdit = true,
  className = '',
  setRowCount = () => null,
  columnDefs = [],
  columnState = null,
  columnStateKey = null,
  frameworkComponents,
  setSelected: parentSetSelected = () => null,
  enableRangeSelection = true,
  enableFillHandle = true,
  enableCharts = true,
  undoRedoCellEditing = true,
  undoRedoCellEditingLimit = 10,
  enableCellChangeFlash = true,
  sideBar,
  toolPanels = null,
  setGridApi,
  infinityGrid,
  gridApi,
  datasource,
  dataCy,
  campaignSubType,
  reportingOutcome = false,
  stopEditingWhenCellsLoseFocus = false,
}: {
  className: string;
  columnDefs?: ColDef[];
  columnState: ColumnState | null;
  columnStateKey?: string | null;
  context?: Record<string, unknown> | null;
  defaultColDef?: ColDef;
  components?: { [index: string]: Component };
  rowClassRules?: { [index: string]: string };
  height: string | undefined;
  heightRatio?: number;
  loading?: boolean;
  loadingOverlayComponent: string;
  onDataChange?: (argo: RowNode[]) => void;
  renderPaginationActions?: (arg0: {
    selected: Deliverable[];
    onDelete?: () => void;
  }) => React.ReactElement | null;
  rowData?: { [index: string]: string }[];
  rowHeight?: number | null | undefined;
  rowSelection?: 'single' | 'multiple';
  setColumnState?: (arg0: ColumnState) => void;
  showPagination?: boolean;
  singleClickEdit?: boolean;
  setRowCount?: (arg0: number) => void;
  setSelected?: (arg0: RowNode[]) => void;
  enableRangeSelection?: boolean;
  enableFillHandle?: boolean;
  enableCharts?: boolean;
  undoRedoCellEditing?: boolean;
  undoRedoCellEditingLimit?: number;
  enableCellChangeFlash?: boolean;
  dataCy?: string;
  frameworkComponents?: Record<string, unknown>;
  toolPanels?: (ToolPanelDef | string)[] | null;
  sideBar?: SideBarDef | string | boolean | string[];
  setGridApi?: (arg0: GridReadyEvent) => void;
  infinityGrid?: boolean;
  gridApi?: GridReadyEvent;
  datasource?: IServerSideDatasource | null;
  campaignSubType: string;
  reportingOutcome?: boolean;
  stopEditingWhenCellsLoseFocus?: boolean;
}) {
  const [selected, setSelected] = React.useState<RowNode[]>([]);

  const onGridReady = (params: GridReadyEvent) => {
    if (setGridApi) {
      setGridApi(params);
    }

    if (reportingOutcome || columnStateKey === null) {
      return;
    }
    loadSavedState(
      params.columnApi,
      columnStateKey,
      Boolean(infinityGrid),
      campaignSubType,
    );
  };

  const onSelectionChanged = (ev: SelectionChangedEvent) => {
    /* @ts-ignore */
    const tmpSelection: RowNode[] = ev.api.getSelectedNodes();
    setSelected(tmpSelection);
    parentSetSelected(tmpSelection);
  };

  const parsedRowHeight = React.useMemo(() => {
    if (rowHeight === null) {
      return undefined;
    }

    if (rowHeight === undefined) {
      return 40;
    }

    return rowHeight;
  }, [rowHeight]);

  return (
    <>
      <InnerGrid
        useDestinationGrid={reportingOutcome}
        dataCy={dataCy}
        datasource={datasource}
        setGridApi={setGridApi}
        gridApi={gridApi}
        infinityGrid={infinityGrid}
        rowModelType={infinityGrid ? 'serverSide' : 'clientSide'}
        serverSideStoreType={'partial' as ServerSideStoreType}
        setColumnState={setColumnState}
        className={className}
        toolPanels={toolPanels}
        enableCellChangeFlash={enableCellChangeFlash}
        undoRedoCellEditing={undoRedoCellEditing}
        undoRedoCellEditingLimit={undoRedoCellEditingLimit}
        enableCharts={enableCharts}
        enableFillHandle={enableFillHandle}
        enableRangeSelection={enableRangeSelection}
        sideBar={sideBar}
        suppressScrollOnNewData
        suppressRowClickSelection
        singleClickEdit={singleClickEdit}
        height={height}
        heightRatio={heightRatio}
        columnState={columnState}
        columnStateKey={columnStateKey}
        loadingOverlayComponent={loadingOverlayComponent}
        columnDefs={columnDefs}
        frameworkComponents={frameworkComponents}
        components={components}
        defaultColDef={defaultColDef}
        context={context as Record<string, unknown>}
        /* @ts-ignore */
        rowHeight={parsedRowHeight}
        rowClassRules={rowClassRules}
        rowSelection={rowSelection}
        onGridReady={onGridReady}
        rowData={rowData || []}
        campaignSubType={campaignSubType}
        onRowDataUpdated={({ api }: DetailGridInfo) => {
          const rows: RowNode[] = [];
          if (!api) {
            return;
          }
          /* @ts-ignore */
          api.forEachNode((rowNode: RowNode) => {
            rows.push(rowNode.data);
          });

          onDataChange(rows);
          if (setRowCount) {
            setRowCount(rows?.length ?? 0);
          }
        }}
        onSelectionChanged={onSelectionChanged}
        stopEditingWhenCellsLoseFocus={stopEditingWhenCellsLoseFocus}
      />
      {showPagination && (
        <Pagination
          cbn={cbn}
          loading={loading}
          selected={selected}
          renderPaginationActions={renderPaginationActions}
        />
      )}
    </>
  );
}
