import Video from 'components/UI/Video/Video';
import ArrowCircleRight from '@UIComponents/_assets/react_svg/ArrowCircleRight';
import { QmSourceV2 } from 'shared/types/Quartermaster';
import './RecentVideos.scss';

export default function RecentVideos({
  productionUrl,
  videos,
}: {
  productionUrl?: string;
  videos: QmSourceV2[];
}) {
  if (!videos || videos?.length === 0 || !productionUrl) {
    return null;
  }

  return (
    <section className="youtube-recent-videos">
      <h2>Recent Videos</h2>

      <section className="youtube-recent-videos__videos-container">
        {videos.map((video) => (
          <div
            key={video?.id}
            className="youtube-recent-videos__videos-container__video"
          >
            <Video
              url={`https://www.youtube.com/embed/${video?.attributes?.sid}`}
            />
          </div>
        ))}

        <div className="svg-container">
          <a href={productionUrl} rel="noreferrer" target="_blank">
            <ArrowCircleRight />
          </a>

          <div className="filler" />
        </div>
      </section>
    </section>
  );
}
