const absoluteHeight = (node) => {
  if (!node) {
    return null;
  }

  const { height } = node.getBoundingClientRect();
  const { marginTop, marginBottom } = window.getComputedStyle(node);

  return height + parseFloat(marginTop) + parseFloat(marginBottom);
};

export default absoluteHeight;
