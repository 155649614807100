import { ApolloClient } from 'apollo-client';
import { createContext } from 'react';
import { ErrorType } from 'store/errorHandler/types';
import { Permissions, User } from 'components/User/types';
import { History } from 'history';
import { Analytics } from '@segment/analytics-next';

export type Versioning = {
  uuid: string;
  lastUpdate: number;
};

export type LoginSession = {
  user: User | null;
  access_token: string;
  permissions: Permissions | null;
};

type ApplicationContextType = {
  apolloClient: ApolloClient<object>;
  errorHandler: {
    push: (errors: ErrorType[]) => void;
    reset: () => void;
    errors: ErrorType[];
  };
  isbToken: string | null;
  isbUser: string | null;
  logout: () => void;
  permissions: Permissions | null;
  permissionsIsLoading: boolean;
  setIsbToken: (isbToken: string) => void;
  setIsbUser: (isbUser: string) => void;
  setPermissions: (permissions: Permissions) => void;
  setSession: (loginSession: LoginSession) => void;
  setToken: (token: string) => void;
  setUser: (user: User) => void;
  setVersioning: (version: Versioning | null) => void;
  token: string | null;
  user: User | null;
  versioning: Versioning | null;
  history: History | null;
  // @ts-ignore
  setSegment?: Dispatch<unknown>;
  segment: Analytics | null;
  loadUser: (arg0?: User | null) => void;
};

// @ts-ignore
const ApplicationContext = createContext<ApplicationContextType>({
  apolloClient: null,
  errorHandler: {
    push: () => {},
    reset: () => {},
    errors: [],
  },
  isbToken: null,
  isbUser: null,
  permissions: null,
  permissionsIsLoading: false,
  setIsbToken: () => {},
  setIsbUser: () => {},
  setPermissions: () => {},
  setToken: () => {},
  setUser: () => {},
  setVersioning: () => {},
  token: null,
  user: null,
  versioning: null,
  history: null,
  // @ts-ignore
  setSegment: () => null,
  segment: null,
  loadUser: () => null,
});

export default ApplicationContext;
