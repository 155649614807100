import ApplicationContext from 'components/Application/Context';
import useStorage from 'components/Application/useStorage';
import React from 'react';

export default function useReportingOutcome() {
  const [reportingOutcome, setReportingOutcome] = React.useState<
    boolean | null
  >(null);

  const { user } = React.useContext(ApplicationContext);
  const reportingOutcomeFeatureFlag = React.useMemo(
    () => Boolean(user?.amplitudeExperiments?.reportingOutcome),
    [user?.amplitudeExperiments?.reportingOutcome],
  );

  const [
    reportingOutcomeLocalStorage,
    setReportingOutcomeLocalStorage,
  ] = useStorage<boolean>('$BEN$--reportingOutcome');

  /* eslint-disable-next-line */
  const isCreatorCampaignPage = new RegExp('/team/influencer/', 'i').test(
    window.location.pathname,
  );

  React.useEffect(() => {
    if (!reportingOutcomeFeatureFlag) {
      return;
    }

    if (
      reportingOutcomeLocalStorage !== null &&
      reportingOutcomeLocalStorage !== reportingOutcomeFeatureFlag
    ) {
      setReportingOutcome(reportingOutcomeLocalStorage);
      return;
    }

    if (reportingOutcomeLocalStorage === null) {
      setReportingOutcomeLocalStorage(reportingOutcomeFeatureFlag);
    }

    setReportingOutcome(reportingOutcomeFeatureFlag);
  }, [
    reportingOutcomeLocalStorage,
    reportingOutcomeFeatureFlag,
    setReportingOutcomeLocalStorage,
  ]);

  return {
    isCreatorCampaignPage,
    reportingOutcomeFeatureFlag,
    reportingOutcomeLocalStorage,
    setReportingOutcome: (value: boolean | null) => {
      setReportingOutcome(value);
      setReportingOutcomeLocalStorage(value);
      window.location.reload();
    },
    reportingOutcome: Boolean(reportingOutcome),
  };
}
