import featureFlags from 'store/featureFlags';
import { User, FeatureFlags } from 'components/User/types';

export const transformUser = (user: User | null) => {
  if (!user) {
    return user;
  }
  return {
    ...user,
    friendlyId: user.id,

    created: {
      at: user.createdAt,
      by: user.createdBy?.id,
    },
    updated: {
      at: user.updatedAt,
      by: user.updatedBy?.id,
    },
    clientId: user.client?.id,
    clientName: user.client?.name,
    brandIds: (user.brands || []).map((brand) => brand.id),
    presets: (user.userPresets || []).reduce(
      (acc, preset) => ({
        ...acc,
        [preset.key]: preset.id,
      }),
      {},
    ),
    amplitudeExperiments: user?.featureFlags?.amplitudeExperiments,
    featureFlags: Object.entries(featureFlags).reduce<FeatureFlags>(
      (acc: FeatureFlags, [key, options]: [any, any]) => {
        if (!options && !key) {
          return acc;
        }

        if (typeof key !== 'string') {
          return acc;
        }

        acc[key] = {
          ...(options || {}),
          ...(user?.featureFlags?.[key] ?? {}),
        };

        return acc;
      },
      {},
    ),
  };
};
