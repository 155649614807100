import get from 'lodash/get';
import { User } from 'components/User/types';
import { Client } from 'components/Client/types';
import storage from 'utils/storage';
import { AmplitudeExperiments } from 'shared/types/user';

export const hasToChangeThePassword = (user: User) =>
  user?.flags?.HasAcceptedWebsiteTerms && user?.flags?.forceChangePassword;

export const hasAcceptedTerms = (user: User) =>
  Boolean(user?.flags?.HasAcceptedWebsiteTerms);

export const isAuthorized = ({
  grantAccess,
  permissions,
  requiredPermission,
  user,
  amplitudeExperiments,
  requiredExperiment,
}: {
  grantAccess?: (permissions: object, user: User) => boolean;
  permissions: object;
  requiredPermission?: string;
  user: User;
  amplitudeExperiments?: AmplitudeExperiments;
  requiredExperiment?: string;
}) =>
  (!grantAccess && !requiredPermission) ||
  (grantAccess && grantAccess(permissions, user)) ||
  (requiredPermission && get(permissions, requiredPermission)) ||
  (requiredPermission && get(amplitudeExperiments, requiredExperiment));

export const isAuthenticated = ({
  token,
  permissions,
  user,
}: {
  token: string | null;
  permissions: object | null;
  user: User | null;
}) => token && permissions && user;

export const hasInternalAppAccess = (user: User & { clients?: Client[] }) => {
  const client = user?.client || user?.clients?.[0];

  return (
    user?.role !== 'external' ||
    ['internal', 'both'].includes(client?.appRouting)
  );
};

export const hasBrandAppAccess = (user: User & { clients?: Client[] }) => {
  const client = user?.client || user?.clients?.[0];

  return (
    user?.role !== 'external' || ['brand', 'both'].includes(client?.appRouting)
  );
};

export const redirectTo = ({
  defaultLocation,
  externalContext,
  grantAccess,
  permissions,
  requiredPermission,
  user,
  amplitudeExperiments,
  requiredExperiment,
}: {
  defaultLocation: string;
  externalContext: boolean;
  grantAccess?: (permissions: object, user: User) => boolean;
  permissions: object;
  requiredPermission?: string;
  token: string;
  user: User;
  amplitudeExperiments?: AmplitudeExperiments;
  requiredExperiment?: string;
}) => {
  // First Access
  if (!hasAcceptedTerms(user)) {
    storage.remove('$BEN$--redirectOnAuthenticated');
    storage.remove('$BEN$--externalRedirectOnAuthenticated');
    return { pathname: '/user/account-access-agreement' };
  }

  if (hasToChangeThePassword(user)) {
    storage.remove('$BEN$--redirectOnAuthenticated');
    storage.remove('$BEN$--externalRedirectOnAuthenticated');
    return { pathname: '/user/change-password' };
  }

  if (
    !isAuthorized({
      user,
      permissions,
      grantAccess,
      requiredPermission,
      amplitudeExperiments,
      requiredExperiment,
    })
  ) {
    return { pathname: defaultLocation, replace: true };
  }

  if (!hasInternalAppAccess(user) && !externalContext) {
    return { pathname: '/campaigns', replace: true };
  }

  if (!hasBrandAppAccess(user) && externalContext) {
    return { pathname: '/search', replace: true };
  }

  return null;
};
