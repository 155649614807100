import ApplicationContext from 'components/Application/Context';
import {
  SegmentActions,
  SegmentEvents,
} from 'components/Segment/segmentEvents';
import { formatCampaignId, formatUserId } from 'components/Segment/utils';
import React, { useContext } from 'react';
import { drawerTabsTexts } from 'components/Creator/constants';
import { DrawerTabsLabels } from 'components/Creator/types';
import BaseButton from '@UIComponents/BaseButton/BaseButton';
import StarRating from 'components/UserFeedback/StarRating/StarRating';
import { Property } from 'components/Property/types';

const initialSurvey = {
  rating: 0,
  input: '',
  extraInput: '',
  sent: false,
};

const STYLES = {
  inlineBlock: 'inline-block',
  whiteBorder: '1.25px solid #FFFFFF',
  purpleBorder: '1.25px solid #6053c3',
  transparent: 'transparent',
  topMargin: '2rem 0 0 0',
};
export default function UnderConstruction({
  drawerTab,
  tab,
  property,
  campaignId,
  userId,
}: {
  drawerTab: DrawerTabsLabels;
  tab: string;
  property: Property;
  campaignId: number;
  userId: number;
}) {
  const { segment } = useContext(ApplicationContext);
  const [survey, setSurvey] = React.useState(initialSurvey);
  React.useEffect(() => {
    setSurvey(initialSurvey);
  }, [drawerTab]);

  const onRateCreatorProfileDrawer = React.useCallback(
    ({
      rating,
      input,
      extraInput: extra_input,
    }: {
      rating: number;
      input: string;
      extraInput: string;
    }) => {
      segment.track(
        SegmentEvents.CreatorProfile,
        {
          action: SegmentActions.DrawerTabRating,
          tab,
          drawer_tab: drawerTabsTexts?.[drawerTab].segmentValue,
          rating,

          input,
          extra_input,
          property: {
            id: property?.id,
            name: property?.name,
          },
        },
        {
          context: { groupId: formatCampaignId(campaignId) },
          userId: formatUserId(userId),
        },
      );
    },
    [drawerTab, campaignId, property, segment, tab, userId],
  );

  const onStarRatingClick = React.useCallback(
    (index: number) => {
      setSurvey({
        ...survey,
        rating: survey.rating === index ? 0 : index,
        sent: false,
      });
    },
    [survey],
  );

  const handleTextareaInput = React.useCallback(
    (ev: { target: { value: string } }) => {
      setSurvey({
        ...survey,
        extraInput: ev?.target?.value,
        sent: false,
      });
    },
    [survey],
  );

  const sendSurveyForm = React.useCallback(() => {
    onRateCreatorProfileDrawer(survey);
    setSurvey({ ...survey, sent: true });
  }, [onRateCreatorProfileDrawer, setSurvey, survey]);

  return (
    <section
      style={{
        backgroundColor: 'rgb(240, 240, 240)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2rem',
        padding: '4rem 6rem',
      }}
    >
      <p
        style={{
          color: '#6053c3',
          fontWeight: '600',
        }}
      >
        {drawerTabsTexts?.[drawerTab]?.underConstructionMessage ?? ''}
      </p>
      <form>
        <StarRating
          onClick={onStarRatingClick}
          rating={survey?.rating}
          sent={survey?.sent}
        />

        <label htmlFor="input">
          <p
            style={{
              fontWeight: '700',
              color: '#6053c3',
              margin: STYLES?.topMargin,
            }}
          >
            Where in your campaign process(es) would this information be most
            valuable, if at all?
          </p>
          <textarea
            disabled={survey.sent}
            value={survey?.input ?? ''}
            id="input"
            style={{
              width: '100%',
            }}
            onChange={(ev) => {
              setSurvey({ ...survey, input: ev?.target?.value, sent: false });
            }}
          />
        </label>

        <label htmlFor="extraInput">
          <p
            style={{
              fontWeight: '700',
              color: '#6053c3',
              margin: STYLES?.topMargin,
            }}
          >
            Any other thoughts? Thanks for testing with us!
          </p>
          <textarea
            value={survey?.extraInput ?? ''}
            disabled={survey.sent}
            id="extraInput"
            onChange={handleTextareaInput}
            style={{
              width: '100%',
            }}
          />
        </label>

        <div style={{ marginTop: '2rem' }}>
          <BaseButton
            disabled={survey?.sent}
            onClick={sendSurveyForm}
            text={survey?.sent ? 'Thank you!' : 'Send'}
            color="purple"
            size="small"
            rounded="rounded-sm"
            type="button"
          />
        </div>
      </form>
    </section>
  );
}
